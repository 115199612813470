<template>
  <div id="app-layout" :class="[globalStore.isSidenavCollapsed && 'g-sidenav-hidden']">
    <Loader v-if="globalStore.isLoading" />
    <Sidenav @open-cookies="cookieModal.open()" @open-create-order="newOrderModal.open()" />
    <main class="main-content position-relative">
      <div class="container-fluid pt-4">
        <slot />
      </div>
      <Footer />
      <Modal ref="cookieModal" title="Wechseln" size="small">
        <div class="row">
          <div class="col-12 mb-3">
            <Select v-model="changeClient" :options="admin.clients" text="name" />
          </div>
          <div class="col-12">
            <Select v-if="changeClient" v-model="changeShop" :options="filteredShops" text="name" />
          </div>
        </div>
        <ModalFooter>
          <Button @click="saveCookies" html-type="primary">
            <Icon icon="fa-check" /> Speichern
          </Button>
        </ModalFooter>
      </Modal>
      <Modal ref="tseModal" title="Kassen" size="small">
        <div class="row">
          <div class="col-12 mb-3">
            <Select :key="fiskalyClient" v-model="tseClient" :options="changeClient.fiskaly_clients" text="name" />
          </div>
        </div>
        <ModalFooter>
          <Button @click="updateFiskalyClient(tseClient.id)" html-type="primary">
            <Icon icon="fa-check" /> Speichern
          </Button>
        </ModalFooter>
      </Modal>
      <Modal ref="newOrderModal" title="Neue Buchung in" size="small">
        <div class="row">
          <div class="col-12 mb-3">
            <Select v-model="changeClient" :options="admin.clients" text="name" />
          </div>
          <div class="col-12">
            <Select v-if="changeClient" v-model="changeShop" :options="filteredShops" text="name" />
          </div>
        </div>
        <ModalFooter>
          <Button @click="createOrder(changeShop.id, changeClient.id)" html-type="primary">
            <Icon icon="fa-plus" /> Erstellen
          </Button>
        </ModalFooter>
      </Modal>
      <Modal ref="recentOrdersModal" size="full no-padding">
        <RecentOrders />
      </Modal>
    </main>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

// eslint-disable-next-line camelcase
import locale_de from "dayjs/locale/de";
import { provideUseId } from "@headlessui/vue";
import Sidenav from "~/components/Layout/Navigation/Sidenav.vue";
import Footer from "~/components/Layout/Footer.vue";
import Loader from "~/components/Layout/Loader.vue";
import RecentOrders from "~/components/Order/RecentOrders.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useTSE } from "~/composables/useTSE";
import { useOrder } from "~/composables/useOrder";

dayjs.locale("de", locale_de);
dayjs.extend(weekday);
provideUseId(() => useId());

const { globalStore } = useNuxtApp();
const route = useRoute();
const authStore = useAuthStore();
authStore.fetch();

if (route.meta.title) useHead({ titleTemplate: route.meta.title });

watch(() => route.path, () => {
  useHead({ titleTemplate: route.meta.title });
});

const { data: admin } = useAuth();
const { createOrder } = useOrder();
const newOrderModal = ref({});
const cookieModal = ref({});
const { tseModal, fiskalyClient, updateFiskalyClient, loadFiskalyClient } = useTSE();
const changeShop = ref({});
const changeClient = ref({});
const recentOrdersModal = useState("recent_orders_modal");
const cookieClient = useCookie("client");
const cookieShop = useCookie("shop");

await loadFiskalyClient();
const tseClient = ref(fiskalyClient.value);

if (admin.value) {
  changeClient.value = admin.value.clients.find(_client => _client.id === cookieClient.value);
  if (changeClient.value) changeShop.value = changeClient.value.shops.find(_shop => _shop.id === cookieShop.value);
}

watch(changeClient, () => {
  if (!changeClient.value.shops.find(_clientShop => _clientShop.id === changeShop.value?.id)) changeShop.value = {};
});

const filteredShops = computed(() => {
  const _shops = [];
  if (changeClient.value) {
    admin.value.shops.forEach((_shop) => {
      const exists = changeClient.value.shops.find(_clientShop => _clientShop.id === _shop.id);
      if (exists) _shops.push(_shop);
    });
  }

  return _shops;
});

async function saveCookies() {
  if (changeShop.value.id && changeClient.value.id) {
    cookieShop.value = changeShop.value.id;
    cookieClient.value = changeClient.value.id;
    location.href = "/";
  }
}

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  for (let i = 0, n = a?.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};
</script>
